@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    padding-top: env(safe-area-inset-top);
    padding-right: env(safe-area-inset-right);
    padding-bottom: env(safe-area-inset-bottom);
    padding-left: env(safe-area-inset-left);
  }
}

@layer utilities {
  .pb-safe {
    padding-bottom: calc(1em + env(safe-area-inset-bottom));
  }
  .mb-safe {
    margin-bottom: calc(1em + env(safe-area-inset-bottom));
  }

  .scrollbar-none {
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .scrollbar-none::-webkit-scrollbar {
    display: none;
  }
}

.stylistic-quote-mark {
  font-size: 6rem;
  right: 95%;
}

.loader {
  border-top-color: #000;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.is-sticky > * {
  -webkit-transform: translateZ(0);
}

.enhanced-scrollbar::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.enhanced-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 4px;
  width: 4px;
  height: 4px;
  background: #969696;
}

.discovery-sidebar {
  height: calc(100vh - 5rem - 3.75rem);
}

.discovery-address-detail {
  height: calc(66.6vh - 5rem - 5rem);
}

.discovery-address-detail-no-map {
  height: calc(100vh - 5rem - 5rem);
}

.discovery-delivery-sidebar {
  height: calc(66.6vh - 5rem - 7.5rem);
}

.discovery-delivery-sidebar-no-map {
  height: calc(100vh - 5rem - 7.5rem);
}

.slick-track {
  display: flex;
}

/* Workaround as `slick` property of the react-slick carousel currently doesn't work */
.slick-slide > div {
  display: inline;
}

#marketing-billboards > * .slick-slide {
  @apply mx-2;
}

#marketing-billboards > * .slick-list {
  @apply -mx-2;
}

.marketing-billboard-dot {
  @apply w-2.5 h-2.5 rounded-full bg-surface-lighter opacity-50;
}

.marketing-billboard-dot-mobile {
  @apply w-1.5 h-1.5;
}

.slick-active > .marketing-billboard-dot {
  @apply rounded-full bg-surface-lighter opacity-100;
}

.slick-arrow {
  @apply absolute bottom-0 z-[5] h-full !flex items-center opacity-0 group-hover:opacity-100;
}

.slick-prev {
  @apply left-0 pl-4;
}

.slick-next {
  @apply right-0 pr-4;
}

@media (min-width: 768px) {
  .sm\:discovery-map {
    width: calc(100vw - 28rem);
  }

  .discovery-delivery-sidebar,
  .discovery-delivery-sidebar-no-map {
    height: calc(100vh - 5rem - 9.5rem);
  }

  .discovery-address-detail,
  .discovery-address-detail-no-map {
    height: 100%;
  }
}

.bg-multiplier_bar_current {
  background: linear-gradient(276deg, #c8b273 53.52%, #f5dfa0 126.76%);
}
